<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="1000"
    :max-width="'90%'"
    :title="$t('customer.vendorSubmissions.submissionHistory')"
  >
    <cz-spinner v-if="loading" class="pa-10" />
    <div
      v-else-if="!items.length"
      class="pa-6 textPrimary--text text-title text-center"
    >
      לא קיימת היסטוריה עבור ספק זה
    </div>
    <v-timeline
      reverse
      :dense="$vuetify.breakpoint.smAndDown"
      class="pa-10"
      v-else
    >
      <v-timeline-item
        v-for="item in items"
        :key="item._id"
        :color="getStatusColor(item.status)"
        :icon="getStatusIcon(item.status)"
        icon-color="white"
        large
      >
        <v-card color="white">
          <v-card-text class="d-flex flex-column" style="row-gap: 10px">
            <div class="d-flex align-center" style="column-gap: 3px">
              <div class="font-weight-semibold text-title textPrimary--text">
                {{ item.statusText }}
              </div>
            </div>
            <div class="d-flex text-body-1 textPrimary--text" v-if="item.note">
              <div class="font-weight-semibold">{{ $t('common.note') }}:</div>
              <div class="mr-1">
                {{ item.note }}
              </div>
              <!-- <div class="text-body-1 textPrimary--text"
              {{ item.note }}
            </div> -->
            </div>
            <div class="text-body-1">
              {{ formatDateTimeToDisplay(item.updatedAt) }}
            </div>
            <div class="text-subtitle-2 textPrimary--text" v-if="item.user">
              {{
                $t('customer.vendorSubmissions.submissionHistoryCreatedBy', [
                  item.user.fullName
                ])
              }}
            </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzSpinner, CzMenuButton } from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import VendorSubmissionStatus from '@/shared/types/VendorSubmissionStatus';
import {
  mdiFileDocumentPlusOutline,
  mdiCheckDecagramOutline,
  mdiArrowRightTopBold,
  mdiFileDocumentAlertOutline,
  mdiAccountClockOutline,
  mdiInformation
} from '@mdi/js';
import { getVendorHistory } from '@/core/api';
export default {
  name: 'VendorApprovalHistoryDialog',
  components: {
    CzDialog,
    CzSpinner,
    CzMenuButton
  },
  props: {
    vendorId: {
      type: String,
      required: true
    }
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      mdiInformation,
      VendorSubmissionStatus,
      items: [],
      loading: false
    };
  },
  methods: {
    formatDateTimeToDisplay,
    async loadData() {
      try {
        this.loading = true;
        const { data } = await getVendorHistory({
          vendorId: this.vendorId
        });
        this.items = data;
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case VendorSubmissionStatus.ACTIVE:
          return 'success darken-1';
        case VendorSubmissionStatus.BLOCKED:
          return 'negative';
        case VendorSubmissionStatus.DRAFT:
          return 'primary darken-1';
        case VendorSubmissionStatus.RETURNED:
          return 'primary darken-1';
        case VendorSubmissionStatus.PENDING_APPROVAL:
          return 'primary darken-1';
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case VendorSubmissionStatus.ACTIVE:
          return mdiCheckDecagramOutline;
        case VendorSubmissionStatus.BLOCKED:
          return mdiFileDocumentAlertOutline;
        case VendorSubmissionStatus.DRAFT:
          return mdiFileDocumentPlusOutline;
        case VendorSubmissionStatus.PENDING_APPROVAL:
        case VendorSubmissionStatus.PENDING_DOCS:
          return mdiAccountClockOutline;
        case VendorSubmissionStatus.RETURNED:
          return mdiArrowRightTopBold;
      }
    },
    getStatusMenuItems(item) {
      return [];
      // switch (item.status) {
      //   case OrderSubmissionStatus.RETURNED:
      //     return [
      //       {
      //         id: 'return-type',
      //         title: this.$t('customer.vendorSubmissions.returnType'),
      //         subtitle: item.returnTypeText
      //       },
      //       {
      //         id: 'note',
      //         title: this.$t('common.note'),
      //         subtitle: item.note
      //       }
      //     ];
      //   case OrderSubmissionStatus.REJECTED:
      //     return [
      //       {
      //         id: 'return-type',
      //         title: this.$t('customer.vendorSubmissions.rejectType'),
      //         subtitle: item.rejectTypeText
      //       },
      //       {
      //         id: 'note',
      //         title: this.$t('common.note'),
      //         subtitle: item.note
      //       }
      //     ];
      //   default:
      //     return [];
      // }
    }
  }
};
</script>

<style></style>
