<template>
  <content-layout
    :show-empty-state="false"
    :loading="false"
    :breadcrumbs-items="breadcrumbsItems"
    :search-placeholder="$t('admin.searchVendors')"
    :search-text.sync="searchText"
    toolbar-enabled
  >
    <template #header>
      <v-card
        class="d-flex align-center full-width justify-space-between"
        outlined
        rounded="lg"
      >
        <v-tabs v-model="tab">
          <v-tab v-for="item in tabs" :key="item.value">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-card>
    </template>

    <template #toolbar>
      <v-spacer />
      <cz-button
        :title="$t('common.filters')"
        :icon-src="
          hasActiveFilters(filters) ? mdiFilterCheckOutline : mdiFilterOutline
        "
        :outlined="!hasActiveFilters(filters)"
        :color="hasActiveFilters(filters) ? 'accent' : 'primary'"
        @click="showFiltersBar = !showFiltersBar"
        :loading="loadingFilters"
      />
    </template>

    <cz-data-table
      v-bind="manageVendorsTable"
      class="mt-4"
      height="calc(100vh - 375px)"
      @click:row="onVendorClicked"
      :options.sync="manageVendorsTable.options"
      :items="filteredVendors"
    >
      <template #item.businessType="{ item }">
        {{ getBusinessTypeText(item.businessType) }}
      </template>
      <template #item.secondaryCategories="{ item }">
        <div class="d-flex flex-wrap py-2" style="gap: 10px">
          <template v-for="(categoryItem, index) in item.secondaryCategory">
            <span
              :key="categoryItem._id"
              color="primary darken-2"
              v-if="index < item.secondaryCategory.length - 1"
            >
              {{ categoryItem.name }},
            </span>
            <span :key="categoryItem._id" v-else>
              {{ categoryItem.name }}
            </span>
          </template>
        </div>
      </template>
      <template #item.actions="{ item }">
        <cz-button
          v-if="currentTab.value !== 'draft'"
          :title="$t('admin.vendorSubmissionHistory')"
          :icon-src="mdiHistory"
          :icon-size="18"
          small
          color="primary"
          dark
          @click.stop="showVendorSubmissionHistory(item)"
        />
      </template>
      <template #item.nav>
        <cz-icon :size="20" :src="mdiChevronLeft" color="textPrimary" />
      </template>
    </cz-data-table>

    <!-- <v-row class="pa-10">
      <v-col
        v-for="item in filteredVendors"
        :key="item._id"
        :xl="2"
        :lg="2"
        :md="4"
        :sm="6"
        :xs="12"
      >
        <v-card
          :min-height="250"
          :hover="tab === 0"
          @click="
            $router.push({
              name: 'manage-vendor',
              params: {
                id: item._id
              }
            })
          "
          class="d-flex flex-column"
        >
          <v-card-title>{{ item.businessName }}</v-card-title>
          <v-card-subtitle class="font-weight-semibold">{{
            `${item._id}, ${getBusinessTypeText(item.businessType)}`
          }}</v-card-subtitle>
          <v-card-text class="flex-grow-1">
            <div class="font-weight-medium">
              {{ item.primaryCategory?.name }}
            </div>

            <div class="d-flex flex-wrap mt-2" style="gap: 10px">
              <template v-for="categoryItem in item.secondaryCategory">
                <cz-chip
                  :text="categoryItem.name"
                  small
                  color="primary darken-2"
                  :key="categoryItem._id"
                />
              </template>
            </div>
          </v-card-text>
          <v-card flat :height="50" v-if="item.lastUpdatedBy">
            <div class="d-flex full-width justify-space-between px-2">
              <cz-button
                :title="$t('admin.vendorSubmissionHistory')"
                :icon-src="mdiHistory"
                :icon-size="18"
                small
                color="primary"
                dark
                @click.stop="showVendorSubmissionHistory(item)"
              />
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row> -->
    <vendor-submission-history-dialog
      v-if="vendorSubmissionHistoryDialog.show"
      v-model="vendorSubmissionHistoryDialog.show"
      v-bind="vendorSubmissionHistoryDialog"
      @close="vendorSubmissionHistoryDialog.show = false"
    />

    <filters-bar
      v-if="filters"
      v-model="showFiltersBar"
      @close="showFiltersBar = false"
      @on-filter="onFilter"
      :filter-fields="filters"
    />
  </content-layout>
</template>

<script>
import { getVendors } from '@/core/api';
import { typesMixin } from '@/shared/mixins';
import cities from '@/data/israeli-cities.json';
import {
  CzSpinner,
  CzEmptyState,
  CzInput,
  CzDataTable,
  CzButton,
  CzIcon,
  CzChip
} from '@/components';
import ContentLayout from '@/layouts/ContentLayout.vue';
import {
  mdiHistory,
  mdiChevronLeft,
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiShapeOutline,
  mdiShapePlusOutline,
  mdiAccountCardOutline,
  mdiCityVariantOutline
} from '@mdi/js';
import { format } from 'date-fns';
import VendorSubmissionHistoryDialog from '../components/VendorApprovalHistoryDialog.vue';
import { manageVendorsColumns } from '../constants';
import {
  formatFiltersForServer,
  hasActiveFilters
} from '@/shared/services/filters.service';
import { mapFields } from 'vuex-map-fields';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default {
  name: 'ManageVendorsPage',
  components: {
    FiltersBar: () => import('@/domain/shared/components/FiltersBar.vue'),
    CzSpinner,
    CzButton,
    CzEmptyState,
    CzInput,
    ContentLayout,
    VendorSubmissionHistoryDialog,
    CzChip,
    CzIcon,
    CzDataTable
  },
  props: {
    status: {
      type: String,
      default: 'pending_approval'
    }
  },
  mixins: [typesMixin],
  created() {
    this.selectDefaultTab();
    this.initializeFilters();
    // this.loadData();
  },
  computed: {
    ...mapFields('vendor', ['primaryCategories', 'secondaryCategories']),
    currentTab() {
      return this.tabs[this.tab];
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$t('admin.adminHomePage'),
          to: {
            name: 'super-admin-home'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('admin.manageVendors'),
          disabled: true,
          exact: true
        }
      ];
    },
    filteredVendors() {
      if (!this.searchText) {
        return this.manageVendorsTable.items;
      }

      const searchLowerCase = this.searchText.toLowerCase();

      return this.manageVendorsTable.items.filter(
        (item) =>
          item.businessName?.toLowerCase().includes(searchLowerCase) ||
          item.contactPersonName?.toLowerCase().includes(searchLowerCase) ||
          item._id?.toLowerCase().includes(searchLowerCase) ||
          item.primaryCategory?.name?.toLowerCase().includes(searchLowerCase) ||
          item.secondaryCategory?.name?.toLowerCase().includes(searchLowerCase)
      );
    }
  },
  data() {
    return {
      format,
      mdiHistory,
      mdiChevronLeft,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      tab: 0,
      loading: false,
      searchText: '',
      initialDataLoaded: false,
      showFiltersBar: false,
      filters: null,
      loadingFilters: false,
      manageVendorsTable: {
        loading: false,
        items: [],
        columns: cloneDeep(manageVendorsColumns(this)),
        options: {},
        serverItemsLength: -1
      },
      vendorSubmissionHistoryDialog: {
        show: false,
        vendorId: null
      },
      tabs: [
        {
          title: this.$t('admin.pendingVendors'),
          value: ['pending_approval', 'pending_docs_update']
        },
        {
          title: this.$t('admin.approvedVendors'),
          value: ['active']
        },
        {
          title: this.$t('admin.returnedVendors'),
          value: ['returned']
        },
        {
          title: this.$t('admin.rejectedVendors'),
          value: ['blocked']
        },
        {
          title: this.$t('admin.draftVendors'),
          value: ['draft']
        }
      ]
    };
  },
  methods: {
    hasActiveFilters,
    selectDefaultTab() {
      // get the tab index by status
      const index = this.tabs.findIndex((item) =>
        item.value.includes(this.status)
      );
      if (index !== -1) {
        this.tab = index;
      }
    },
    onVendorClicked(item) {
      this.$router.push({
        name: 'manage-vendor',
        params: {
          id: item._id
        }
      });
    },

    async initializeFilters() {
      try {
        this.loadingFilters = true;
        await this.loadCategories();
      } catch (error) {
      } finally {
        this.loadingFilters = false;
      }

      const filters = [
        {
          fieldname: '_id',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        },
        {
          fieldname: 'primaryCategoryId',
          label: this.$t('customer.vendorLookup.filters.primaryCategory'),
          type: 'autocomplete',
          multiple: false,
          itemText: 'name',
          operator: 'in',
          itemValue: '_id',
          items: this.primaryCategories || [],
          icon: mdiShapeOutline,
          value: null
        },
        {
          fieldname: 'secondaryCategoryId',
          label: this.$t('customer.vendorLookup.filters.secondaryCategory'),
          type: 'tags',
          itemText: 'name',
          itemValue: '_id',
          operator: 'in',
          itemsHandler: (fields) => {
            const primaryCategory = fields.find(
              (item) => item.fieldname === 'primaryCategoryId'
            );
            if (primaryCategory?.value) {
              return this.secondaryCategories.filter(
                (item) => item.categoryId === primaryCategory.value
              );
            }
            return [];
          },
          disabledHandler: (fields) => {
            const primaryCategory = fields.find(
              (item) => item.fieldname === 'primaryCategoryId'
            );
            return primaryCategory?.value ? false : true;
          },
          icon: mdiShapePlusOutline,
          value: []
        },
        {
          fieldname: 'city',
          label: this.$t('customer.vendorLookup.filters.city'),
          type: 'tags',
          itemText: 'name',
          itemValue: 'name',
          icon: mdiCityVariantOutline,
          operator: 'in',
          items: cities,
          value: []
        }
      ];

      if (this.currentTabId === 'active-vendors') {
        filters.unshift({
          fieldname: '_id',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        });
      }

      this.filters = cloneDeep(filters);
    },
    onFilter(filters) {
      if (this.manageVendorsTable.options) {
        this.manageVendorsTable.options.page = 1;
      }

      this.showFiltersBar = false;
      if (!isEqual(this.filters, filters)) {
        this.filters = cloneDeep(filters);
        this.loadData();
      }
    },
    async loadData() {
      try {
        this.manageVendorsTable.loading = true;
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.manageVendorsTable.options;

        const $skip = (page - 1) * itemsPerPage;
        const status = { $in: this.tabs[this.tab]?.value };

        const query = {
          status,
          $limit: itemsPerPage,
          $skip,
          $populate: ['categories']
        };

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        if (this.filters?.length) {
          const formattedFilters = formatFiltersForServer(
            cloneDeep(this.filters)
          );
          formattedFilters.forEach((field) => {
            query[field.fieldname] = field.value;
          });
        }

        this.manageVendorsTable.items = [];
        const { data, total } = await getVendors(query);
        this.initialDataLoaded = true;
        this.manageVendorsTable.serverItemsLength = total;
        this.manageVendorsTable.items = data;
      } finally {
        this.manageVendorsTable.loading = false;
      }
    },
    showVendorSubmissionHistory(item) {
      this.vendorSubmissionHistoryDialog.vendorId = item._id;
      this.vendorSubmissionHistoryDialog.show = true;
    }
  },
  watch: {
    tab() {
      this.loadData();
    },
    'manageVendorsTable.options': {
      handler() {
        this.loadData();
      }
    },
    searchText: {
      handler(value) {}
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
