const VendorSubmissionStatus = {
  DRAFT: 'draft',
  PENDING_APPROVAL: 'pending_approval',
  RETURNED: 'returned',
  BLOCKED: 'blocked',
  ACTIVE: 'active',
  PENDING_DOCS: 'pending_docs_update'
};

export default Object.freeze(VendorSubmissionStatus);
